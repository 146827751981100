import api from '../library/axios';

export default {
  getList({
    workspace_id,
    page,
    start_date,
    end_date,
    date_filter_type,
    status_filter,
  }) {
    return api({
      method: 'get',
      url: `/api/reports/${workspace_id}`,
      params: {
        page,
        start_date,
        end_date,
        date_filter_type,
        status_filter,
      },
    });
  },
  download({
    workspace_id,
    page,
    start_date,
    end_date,
    date_filter_type,
    status_filter,
  }) {
    return api({
      method: 'get',
      url: `/api/reports/${workspace_id}/download`,
      params: {
        page,
        start_date,
        end_date,
        date_filter_type,
        status_filter,
      },
    });
  },
  batchList({
    workspace_id,
    page,
    start_date,
    end_date,
  }) {
    return api({
      method: 'get',
      url: `/api/broadcast/reports/${workspace_id}`,
      params: {
        page,
        start_date,
        end_date,
      },
    });
  },
  downloadBatchList({
    workspace_id,
    page,
    start_date,
    end_date,
  }) {
    return api({
      method: 'get',
      url: `/api/broadcast/reports/${workspace_id}/download`,
      params: {
        page,
        start_date,
        end_date,
        date_range: [
          start_date,
          end_date,
        ],
      },
    });
  },
  batchDetail({
    workspace_id,
    batch_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/broadcast/reports/${workspace_id}/${batch_id}`,
      params: query,
    });
  },
  batchDetailDownload({
    workspace_id,
    batch_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/broadcast/reports/${workspace_id}/${batch_id}/download`,
      params: query,
    });
  },
  downloadList({
    workspace_id,
    page,
  }) {
    return api({
      method: 'get',
      url: `/api/downloads/reports/${workspace_id}`,
      params: {
        page,
      },
    });
  },
  downloadScheduleList({
    workspace_id,
    page,
  }) {
    return api({
      method: 'get',
      url: `/api/downloads/reports/schedule/${workspace_id}`,
      params: {
        page,
      },
    });
  },
  sessionList({
    workspace_id,
    channel_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/channel-sessions/${workspace_id}/${channel_id}`,
      params: query,
    });
  },
  downloadSessionList({
    workspace_id,
    channel_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/channel-sessions/${workspace_id}/${channel_id}/download`,
      params: query,
    });
  },
  callList({
    workspace_id,
    page,
    start_date,
    end_date,
    direction_filter,
    status_filter,
  }) {
    return api({
      method: 'get',
      url: `/api/call/reports/${workspace_id}`,
      params: {
        page,
        start_date,
        end_date,
        direction_filter,
        status_filter,
      },
    });
  },
  downloadCallList({
    workspace_id,
    page,
    start_date,
    end_date,
    call_type,
    status_filter,
  }) {
    return api({
      method: 'get',
      url: `/api/call/reports/${workspace_id}/download`,
      params: {
        page,
        start_date,
        end_date,
        call_type,
        status_filter,
      },
    });
  },
  mauList({
    workspace_id,
    channel_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/mau-usage-reports/${workspace_id}/${channel_id}`,
      params: query,
    });
  },
  downloadMauList({
    workspace_id,
    channel_id,
    query,
  }) {
    return api({
      method: 'get',
      url: `/api/mau-usage-reports/${workspace_id}/${channel_id}/download`,
      params: query,
    });
  },
};
